import { defineStore } from "pinia";
import { getFetch, postFetch, deleteFetch } from "../utils/rawFetch.js";
import { handleError } from "@/utils/functions.js";
import { Loading, Notify } from "quasar";
import { assign } from "misc-helpers";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  installments: [],
  installment: {},
};

// ------------------------------------------------------------------------------------------------

const installmentStore = defineStore({
  id: "AO_Installment",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    createInstallment,
    listInstallments,
    editInstallment,
    deleteInstallment,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.installments = [];
  this.installment = {};
}

// ------------------------------------------------------------------------------------------------

async function createInstallment(installment) {
  Loading.show({
    message: i18nT(`messages:requests.installment.create.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_INSTALLMENT_CREATE,
      {
        body: installment,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.installment.create.success`),
    });

    this.installments.push(res.newInstallment);

    return res.newInstallment;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listInstallments(filters = {}) {
  Loading.show({
    message: i18nT(`messages:requests.installment.list.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_INSTALLMENT_LIST,
      {
        query: filters,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.installments = res.installments;
    return res.installments;
  } catch (error) {
    this.installments = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editInstallment(editedInstallment) {
  Loading.show({
    message: i18nT(`messages:requests.installment.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_INSTALLMENT_EDIT,
      {
        body: editedInstallment,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.installment.edit.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function deleteInstallment(id) {
  Loading.show({
    message: i18nT(`messages:requests.installment.delete.request`),
  });
  try {
    const res = await deleteFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_INSTALLMENT_DELETE,
      {
        query: { id },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.installment.delete.success`),
    });

    this.installments = this.installments.filter((installment) => installment.id != id);

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

export default installmentStore;

// ------------------------------------------------------------------------------------------------
