import { defineStore } from "pinia";
import { getFetch, postFetch, deleteFetch } from "../utils/rawFetch.js";
import { handleError } from "@/utils/functions.js";
import { Loading, Notify } from "quasar";
import { assign } from "misc-helpers";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  transactions: [],
  transaction: {},
  favoriteTransactions: [],
};

// ------------------------------------------------------------------------------------------------

const transactionStore = defineStore({
  id: "AO_Transaction",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    createTransaction,
    listTransactions,
    editTransaction,
    deleteTransaction,
    favoriteTransactions,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.transactions = [];
  this.transaction = {};
  this.favoriteTransactions = [];
}

// ------------------------------------------------------------------------------------------------

async function createTransaction(transaction) {
  Loading.show({
    message: i18nT(`messages:requests.transaction.create.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSACTION_CREATE,
      {
        body: transaction,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.transaction.create.success`),
    });

    this.transactions.push(res.newTransaction);

    return res.newTransaction;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listTransactions(filters = {}) {
  Loading.show({
    message: i18nT(`messages:requests.transaction.list.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSACTION_LIST,
      {
        query: filters,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.transactions = res.transactions;
    return res.transactions;
  } catch (error) {
    this.transactions = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editTransaction(editedTransaction) {
  Loading.show({
    message: i18nT(`messages:requests.transaction.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSACTION_EDIT,
      {
        body: editedTransaction,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.transaction.edit.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function deleteTransaction(id) {
  Loading.show({
    message: i18nT(`messages:requests.transaction.delete.request`),
  });
  try {
    const res = await deleteFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSACTION_DELETE,
      {
        query: { id },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.transaction.delete.success`),
    });

    this.transactions = this.transactions.filter(
      (transaction) => transaction.id != id
    );

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function favoriteTransactions() {
  Loading.show({
    message: i18nT(`messages:requests.transaction.favorite.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSACTION_FAVORITE
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.favoriteTransactions = res.transactions;
    return this.favoriteTransactions;
  } catch (error) {
    this.favoriteTransactions = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

export default transactionStore;

// ------------------------------------------------------------------------------------------------
