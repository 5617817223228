import { createPinia } from "pinia";
export const pinia = createPinia();

// ------------------------------------------------------------------------------------------------

import * as constants from "@/stores/consts.js";
import { piniaFunctions } from "@/stores/storage.js";
import { handleError } from "@/utils/functions.js";
import { t } from "i18next";

// ------------------------------------------------------------------------------------------------

import useAppStore from "./app.js";
import useAccountStore from "./account.js";
import useBeneficiaryStore from "./beneficiary.js";
import useCreditCardStore from "./creditCard.js";
import useInstallmentStore from "./installment.js";
import useJQTreeStore from "./category/jqtreeStore.js";
import useModalStore from "./modal.js";
import useTransactionStore from "./transaction.js";
import useTransferStore from "./transfer.js";
import useUserStore from "./user.js";
import useWorkboxStore from "./workbox.js";

// ------------------------------------------------------------------------------------------------

export const appStore = useAppStore(pinia);
export const accountStore = useAccountStore(pinia);
export const beneficiaryStore = useBeneficiaryStore(pinia);
export const creditCardStore = useCreditCardStore(pinia);
export const installmentStore = useInstallmentStore(pinia);
export const JQTreeStore = useJQTreeStore(pinia);
export const modalStore = useModalStore(pinia);
export const transactionStore = useTransactionStore(pinia);
export const transferStore = useTransferStore(pinia);
export const userStore = useUserStore(pinia);
export const wbStore = useWorkboxStore(pinia);

// ------------------------------------------------------------------------------------------------

export const CONSTS = constants;
export const i18nT = t;

// ------------------------------------------------------------------------------------------------

export let IS_READY = false;
export async function loadStores() {
  try {
    if (!IS_READY) {
      // a ordem do carregamento importa!
      await piniaFunctions.getPiniaStoreInStorage(appStore, appStore.$id);
      await piniaFunctions.setPiniaStoreInStorage(appStore, appStore.$id, {
        ignoreKeys: ["filters"],
      });
      await piniaFunctions.getPiniaStoreInStorage(wbStore, wbStore.$id);
      await piniaFunctions.setPiniaStoreInStorage(wbStore, wbStore.$id);
    }
    IS_READY = true;
    return {
      appStore,
      accountStore,
      beneficiaryStore,
      creditCardStore,
      installmentStore,
      JQTreeStore,
      modalStore,
      transactionStore,
      transferStore,
      userStore,
      i18nT,
      CONSTS,
      wbStore,
    };
  } catch (error) {
    handleError(error, "Erro ao carregar a store do aplicativo.");
  }
}

// ------------------------------------------------------------------------------------------------

export const clearStores = () => {
  appStore.$reset();
  wbStore.$reset();
  logoutStores();
};

// ------------------------------------------------------------------------------------------------

export function logoutStores() {
  accountStore.$reset();
  beneficiaryStore.$reset();
  creditCardStore.$reset();
  installmentStore.$reset();
  JQTreeStore.$reset();
  modalStore.$reset();
  transactionStore.$reset();
  transferStore.$reset();
  userStore.$reset();
}

// ------------------------------------------------------------------------------------------------
