import { createRouter, createWebHashHistory } from "vue-router";
import { loadStoreAsync, handleError } from "@/utils/functions.js";
import { HOME_ROUTES } from "./routeIndex.js";
import { Notify } from "quasar";
import { t } from "i18next";
const i18nT = t;

import accountRoutes from "./accountRoutes.js";
import beneficiaryRoutes from "./beneficiaryRoutes.js";
import categoryRoutes from "./categoryRoutes.js";
import creditCardRoutes from "./creditCardRoutes.js";
import installmentRoutes from "./installmentRoutes.js";
import homeRoutes from "./homeRoutes.js";
import transactionRoutes from "./transactionRoutes.js";
import transferRoutes from "./transferRoutes.js";
import userRoutes from "./userRoutes.js";
import uploadRoutes from "./uploadRoutes.js";

// ------------------------------------------------------------------------------------------------

const router = createRouter({
  history: createWebHashHistory(),
  routes: [
    ...accountRoutes,
    ...beneficiaryRoutes,
    ...categoryRoutes,
    ...creditCardRoutes,
    ...installmentRoutes,
    ...transactionRoutes,
    ...transferRoutes,
    ...userRoutes,
    ...uploadRoutes,

    // deve ser sempre o último a ser carregado!
    ...homeRoutes,
  ],
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 };
  },
});

// ------------------------------------------------------------------------------------------------

const ACCESS_GRANTED = [
  HOME_ROUTES.ERROR.path,
  HOME_ROUTES.LOGIN.path,
  HOME_ROUTES.ROOT.path,
  HOME_ROUTES.HOME.path,
  HOME_ROUTES.TIP.path,
  HOME_ROUTES.LANGUAGE.path,
  HOME_ROUTES.REQUEST_EMAIL.path,
  HOME_ROUTES.VALIDATE_EMAIL.path,
  HOME_ROUTES.CHANGE_PASSWORD.path,
  HOME_ROUTES.FORGET_PASSWORD.path,
];

// ------------------------------------------------------------------------------------------------

router.beforeEach(async (to, from, next) => {
  try {
    // carregando as configurações do app do navegador
    const { accountStore, userStore, logoutStores } = await loadStoreAsync();

    if (import.meta.env.VITE_APP_ENV == "D") {
      console.log(homeRoutes);
      console.info("ENV: ", import.meta.env);
      console.info("ROUTE-TO>>", to);
      console.info("ROUTE-FROM>>", from);
      if (import.meta.env.VITE_APP_IS_DEVELOPING == "true") {
        return next();
      }
    }

    // // rotas liberadas - não precisa checar permissões de acesso então somente entra.
    // // caso não esteja logado a primeira vez que entra vai tentar acessar e vai ser redirecionado
    // // então vai entrar nesse IF.
    if (ACCESS_GRANTED.includes(to.path)) {
      return next();
    }

    // checking if session is valid
    const hasSession = await userStore.pingSession();
    if (!hasSession) {
      await logoutStores();
      Notify.create({
        type: "warning",
        message: i18nT("messages:requests.user.session.error.userNotLogged"),
      });
      return next({ name: HOME_ROUTES.LOGIN.name });
    }

    // checking if user has information fullfilled
    if (userStore.user.id) {
      return next();
    }

    const infoData = await userStore.info();
    if (!userStore.user.id || !userStore.user.nickname) {
      await logoutStores();
      return next({ name: HOME_ROUTES.ERROR.name });
    }

    accountStore.accounts = infoData.accounts;
    return next();
  } catch (error) {
    handleError(error);
    return next({
      name: HOME_ROUTES.ERROR.name,
      query: { msg: error.message || error },
    });
  }
});

// ------------------------------------------------------------------------------------------------

export default router;

// ------------------------------------------------------------------------------------------------
