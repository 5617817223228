// importing spaStorage
import { spaStorage, piniaFunctions } from "spa-storage";

// ------------------------------------------------------------------------------------------------

let name = "AO";
let storeName = "AO_DB";
let encrypted = true;

// ------------------------------------------------------------------------------------------------

if (import.meta.env.VITE_APP_ENV == "D") {
  name = "AO_DEV";
  storeName = "AO_DB_DEV";
  encrypted = false;
} else if (import.meta.env.VITE_APP_ENV == "H") {
  name = "AO_HOMOLOG";
  storeName = "AO_DB_HOMOLOG";
  encrypted = false;
}

// ------------------------------------------------------------------------------------------------

// starting forage to use IndexedDB
spaStorage.startForage({ encrypted }, { name, storeName });

// ------------------------------------------------------------------------------------------------

// starting storage to use localStorage
export { spaStorage, piniaFunctions };

// ------------------------------------------------------------------------------------------------
