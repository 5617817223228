export const PATH_PREFIX = import.meta.env.VITE_APP_ROOT_PATH;

// ------------------------------------------------------------------------------------------------

export const HOME_SECTION = `${PATH_PREFIX}/`;
export const HOME_ROUTES = {
  TIP: {
    path: `${HOME_SECTION}tip`,
    name: "tip",
    title: "common:routes.home.tip",
  },
  ERROR: {
    path: `${HOME_SECTION}error`,
    name: "homeError",
    title: "common:routes.home.error",
  },
  HOME: {
    path: `${HOME_SECTION}home`,
    name: "homeIndex",
    title: "common:routes.home.index",
  },
  LOGIN: {
    path: `${HOME_SECTION}login`,
    name: "login",
    title: "common:routes.home.login",
  },
  LANGUAGE: {
    path: `${HOME_SECTION}language`,
    name: "language",
    title: "common:routes.home.language",
  },
  FORGET_PASSWORD: {
    path: `${HOME_SECTION}forget_password`,
    name: "forgetPassword",
    title: "common:routes.home.forgetPassword",
  },
  CHANGE_PASSWORD: {
    path: `${HOME_SECTION}change_password`,
    name: "changePassword",
    title: "common:routes.home.changePassword",
  },
  REQUEST_EMAIL: {
    path: `${HOME_SECTION}request_email`,
    name: "requestEmail",
    title: "common:routes.home.requestEmail",
  },
  VALIDATE_EMAIL: {
    path: `${HOME_SECTION}validate_email`,
    name: "validateEmail",
    title: "common:routes.home.validateEmail",
  },
  ROOT: {
    path: `${HOME_SECTION}`,
    name: "homeRoot",
  },
};

// ------------------------------------------------------------------------------------------------

export const USER_SECTION = `${PATH_PREFIX}/user/`;
export const USER_ROUTES = {
  INFO: {
    path: `${USER_SECTION}info`,
    name: "userInfo",
    title: "common:routes.user.info",
  },
  HOME: {
    path: `${USER_SECTION}home`,
    name: "userHome",
    title: "common:routes.user.home",
  },
  STATEMENT: {
    path: `${USER_SECTION}statement`,
    name: "userStatement",
    title: "common:routes.user.statement",
  },
  WEBAUTHN: {
    path: `${USER_SECTION}webauthn`,
    name: "userWebAuthn",
    title: "common:routes.user.webauthn",
  },
  EXPORT: {
    path: `${USER_SECTION}export`,
    name: "userExport",
    title: "common:routes.user.export",
  }
};

// ------------------------------------------------------------------------------------------------

export const ACCOUNT_SECTION = `${PATH_PREFIX}/account/`;
export const ACCOUNT_ROUTES = {
  LIST: {
    path: `${ACCOUNT_SECTION}list`,
    name: "accountList",
    title: "common:routes.account.list",
  },
  CREATE: {
    path: `${ACCOUNT_SECTION}create`,
    name: "accountCreate",
    title: "common:routes.account.create",
  },
  EDIT: {
    path: `${ACCOUNT_SECTION}edit`,
    name: "accountEdit",
    title: "common:routes.account.edit",
  },
};

// ------------------------------------------------------------------------------------------------

export const BENEFICIARY_SECTION = `${PATH_PREFIX}/beneficiary/`;
export const BENEFICIARY_ROUTES = {
  LIST: {
    path: `${BENEFICIARY_SECTION}list`,
    name: "beneficiaryList",
    title: "common:routes.beneficiary.list",
  },
  CREATE: {
    path: `${BENEFICIARY_SECTION}create`,
    name: "beneficiaryCreate",
    title: "common:routes.beneficiary.create",
  },
  EDIT: {
    path: `${BENEFICIARY_SECTION}edit`,
    name: "beneficiaryEdit",
    title: "common:routes.beneficiary.edit",
  },
};

// ------------------------------------------------------------------------------------------------

export const CREDIT_CARD_SECTION = `${PATH_PREFIX}/credit_card/`;
export const CREDIT_CARD_ROUTES = {
  LIST: {
    path: `${CREDIT_CARD_SECTION}list`,
    name: "creditCardList",
    title: "common:routes.creditCard.list",
  },
  CREATE: {
    path: `${CREDIT_CARD_SECTION}create`,
    name: "creditCardCreate",
    title: "common:routes.creditCard.create",
  },
  EDIT: {
    path: `${CREDIT_CARD_SECTION}edit`,
    name: "creditCardEdit",
    title: "common:routes.creditCard.edit",
  },
};

// ------------------------------------------------------------------------------------------------

export const TRANSACTION_SECTION = `${PATH_PREFIX}/transaction/`;
export const TRANSACTION_ROUTES = {
  LIST: {
    path: `${TRANSACTION_SECTION}list`,
    name: "transactionList",
    title: "common:routes.transaction.list",
  },
  CREATE: {
    path: `${TRANSACTION_SECTION}create`,
    name: "transactionCreate",
    title: "common:routes.transaction.create",
  },
  EDIT: {
    path: `${TRANSACTION_SECTION}edit`,
    name: "transactionEdit",
    title: "common:routes.transaction.edit",
  },
};

// ------------------------------------------------------------------------------------------------

export const TRANSFER_SECTION = `${PATH_PREFIX}/transfer/`;
export const TRANSFER_ROUTES = {
  LIST: {
    path: `${TRANSFER_SECTION}list`,
    name: "transferList",
    title: "common:routes.transfer.list",
  },
  CREATE: {
    path: `${TRANSFER_SECTION}create`,
    name: "transferCreate",
    title: "common:routes.transfer.create",
  },
  EDIT: {
    path: `${TRANSFER_SECTION}edit`,
    name: "transferEdit",
    title: "common:routes.transfer.edit",
  },
};

// ------------------------------------------------------------------------------------------------

export const INSTALLMENT_SECTION = `${PATH_PREFIX}/installment/`;
export const INSTALLMENT_ROUTES = {
  LIST: {
    path: `${INSTALLMENT_SECTION}list`,
    name: "installmentList",
    title: "common:routes.installment.list",
  },
  CREATE: {
    path: `${INSTALLMENT_SECTION}create`,
    name: "installmentCreate",
    title: "common:routes.installment.create",
  },
  EDIT: {
    path: `${INSTALLMENT_SECTION}edit`,
    name: "installmentEdit",
    title: "common:routes.installment.edit",
  },
};

// ------------------------------------------------------------------------------------------------

export const CATEGORY_SECTION = `${PATH_PREFIX}/category/`;
export const CATEGORY_ROUTES = {
  LIST: {
    path: `${CATEGORY_SECTION}list`,
    name: "categoryList",
    title: "common:routes.category.list",
  },
};

// ------------------------------------------------------------------------------------------------

export const UPLOAD_SECTION = `${PATH_PREFIX}/upload/`;
export const UPLOAD_ROUTES = {
  FILE: {
    path: `${UPLOAD_SECTION}file`,
    name: "uploadFile",
    title: "common:routes.upload.file",
  },
  LIST: {
    path: `${UPLOAD_SECTION}list`,
    name: "uploadList",
    title: "common:routes.upload.list",
  },
};

// ------------------------------------------------------------------------------------------------