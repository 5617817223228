import { USER_ROUTES, USER_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: USER_SECTION,
    name: "userRoot",
    redirect: () => {
      return { name: USER_ROUTES.HOME.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: USER_ROUTES.HOME.path,
        name: USER_ROUTES.HOME.name,
        component: () => import("@/views/user/home/AO_UserHomeView.vue"),
      },
      {
        path: USER_ROUTES.STATEMENT.path,
        name: USER_ROUTES.STATEMENT.name,
        component: () => import("@/views/user/statement/AO_UserStatementView.vue"),
      },
      {
        path: USER_ROUTES.WEBAUTHN.path,
        name: USER_ROUTES.WEBAUTHN.name,
        component: () => import("@/views/user/AO_UserWebAuthnCredentialsView.vue"),
      },
      {
        path: USER_ROUTES.INFO.path,
        name: USER_ROUTES.INFO.name,
        component: () => import("@/views/user/info/AO_UserInfoView.vue"),
      },
      {
        path: USER_ROUTES.EXPORT.path,
        name: USER_ROUTES.EXPORT.name,
        component: () => import("@/views/user/export/AO_UserExportView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
