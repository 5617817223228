import { defineStore } from "pinia";
import { assign } from "misc-helpers";
import { t } from "i18next";

// ------------------------------------------------------------------------------------------------

const stateProps = {
  modal: {
    opened: false,
    persistent: false,
    title: "",
    content: "",
    cancelLabel: t("common:buttons.close"),
    cancelColor: "secondary",
    onCancel: () => {},
    onConfirm: () => {},
    confirmLabel: t("common:buttons.confirm"),
    confirmColor: "primary",
    showConfirm: true,
  },
};

// ------------------------------------------------------------------------------------------------

const modalStore = defineStore({
  id: "AO_Modal",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    setModal,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function setModal(props) {
  stateProps.modal.confirmLabel = t("common:buttons.confirm");
  stateProps.modal.cancelLabel = t("common:buttons.cancel");
  this.modal = assign(stateProps.modal, props);
}

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.modal = assign({}, stateProps.modal);
}

// ------------------------------------------------------------------------------------------------

export default modalStore;

// ------------------------------------------------------------------------------------------------
