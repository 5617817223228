import { HOME_ROUTES } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: HOME_ROUTES.ROOT.path,
    name: HOME_ROUTES.ROOT.name,
    redirect: () => {
      return { name: HOME_ROUTES.LOGIN.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: HOME_ROUTES.HOME.path,
        name: HOME_ROUTES.HOME.name,
        component: () => import("@/views/home/AO_HomeView.vue"),
      },
      {
        path: HOME_ROUTES.LOGIN.path,
        name: HOME_ROUTES.LOGIN.name,
        component: () => import("@/views/home/login/AO_LoginView.vue"),
      },
      {
        path: HOME_ROUTES.TIP.path,
        name: HOME_ROUTES.TIP.name,
        component: () => import("@/views/home/AO_TipView.vue"),
      },
      {
        path: HOME_ROUTES.LANGUAGE.path,
        name: HOME_ROUTES.LANGUAGE.name,
        component: () => import("@/views/home/AO_LanguageView.vue"),
      },
      {
        path: HOME_ROUTES.REQUEST_EMAIL.path,
        name: HOME_ROUTES.REQUEST_EMAIL.name,
        component: () => import("@/views/home/AO_RequestEmailView.vue"),
      },
      {
        path: HOME_ROUTES.FORGET_PASSWORD.path,
        name: HOME_ROUTES.FORGET_PASSWORD.name,
        component: () => import("@/views/home/AO_ForgetPasswordView.vue"),
      },
      {
        path: HOME_ROUTES.CHANGE_PASSWORD.path,
        name: HOME_ROUTES.CHANGE_PASSWORD.name,
        component: () => import("@/views/home/AO_ChangePasswordView.vue"),
      },
      {
        path: HOME_ROUTES.VALIDATE_EMAIL.path,
        name: HOME_ROUTES.VALIDATE_EMAIL.name,
        component: () => import("@/views/home/AO_ValidateEmailView.vue"),
      },
      {
        path: HOME_ROUTES.ERROR.path,
        name: HOME_ROUTES.ERROR.name,
        component: () => import("@/views/home/AO_ErroView.vue"),
      },

      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
  // deve ser o último sempre - 404
  {
    path: "/:pathMatch(.*)*",
    name: "notFound2",
    redirect: () => {
      return { name: HOME_ROUTES.HOME.name };
    },
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
