import { i18nT, modalStore } from "../../stores";
import { WB_LOG_PREFIX } from "../consts.js";
import { handleError } from "../../utils/functions.js";

// ------------------------------------------------------------------------------------------------

export default (newWorker) => {
  try {
    console.info(`${WB_LOG_PREFIX} prompting update to user...`);

    modalStore.setModal({
      title: i18nT("common:sw.questions.updateSW.title"),
      content: i18nT("common:sw.questions.updateSW.message"),
      opened: true,
      showConfirm: true,
      confirmColor: "positive",
      confirmLabel: i18nT("common:sw.questions.updateSW.confirmLabel"),
      cancelLabel: i18nT("common:sw.questions.updateSW.cancelLabel"),
      onConfirm: () => {
        console.info(`${WB_LOG_PREFIX} update version granted...`);
        newWorker.postMessage({ action: "skipWaiting" });
        return;
      },
      onCancel: () => {
        console.info("SKIP");
        console.info(`${WB_LOG_PREFIX} update version denied...`);
        return;
      },
    });
  } catch (error) {
    handleError(error);
  }
};

// ------------------------------------------------------------------------------------------------
