// --------------------------------  Basic Types  -------------------------------------------------

export const _30_DAYS_MILLISECONDS = 2592000000;

// ------------------------------------------------------------------------------------------------

export const APP_PATH_SEPARATOR = '#';
export const APP_NAME = 'Accounting Overview';
export const APP_NAME_ABBR = 'OA';
export const APP_PREFIX = 'AO_';

// ------------------------------------------------------------------------------------------------

export const DB_FORMAT_DATE_COMPLETE = "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'";
export const DB_FORMAT_APP_DATE_COMPLETE = 'yyyy-MM-dd HH:mm:ss';
export const DB_FORMAT_APP_DATE = 'yyyy-MM-dd';
export const DB_FORMAT_READABLE_COMPLETE_DATE = 'dd-MM-yyyy HH:mm:ss';
export const DB_FORMAT_READABLE_DATE = 'dd-MM-yyyy';

// ------------------------------------------------------------------------------------------------
// --------------------------------  Objects  -----------------------------------------------------

export const CURRENCY_BY_LOCALE = {
	'en-us': 'USD',
	'pt-br': 'BRL',
	"es-es": "EUR",
  "fr-fr": "EUR",
  "it-it": "EUR",
	"de-de": "EUR",
	"eo-eo": "EUR",
	"zh-cn": "CNY",
	"ja-jp": "JPY",
	"hi-in": "INR",
};

// ------------------------------------------------------------------------------------------------

export const BENEFICIARY_TYPE = {
	INDIVIDUAL: 'I',
	ORGANIZATION: 'O'
};

// ------------------------------------------------------------------------------------------------

export const ENTRY_TYPE_COLORS = {
	D: 'color: var(--ao-color-red);',
	C: 'color: var(--ao-color-green);'
};

// ------------------------------------------------------------------------------------------------

export const FREQUENCY_TYPES = {
	WEEKLY: 'W',
	MONTHLY: 'M',
	YEARLY: 'Y',
	DAILY: 'D'
};

// ------------------------------------------------------------------------------------------------
