import { defineStore } from "pinia";
import { assign, generateSimpleId } from "misc-helpers";

// ------------------------------------------------------------------------------------------------

const NODE_INFO = {
	// propriedades JQTree
	id: undefined,
	name: undefined,
	children: []
};

const stateProps = {
  modal: {
    opened: false,
    title: "",
    message: "",
  },
  JQTreeProps: {
    // propriedades JQTree
    isExpanded: true,
  },
  node: assign({}, NODE_INFO),
};

// ------------------------------------------------------------------------------------------------

const JQTreeStore = defineStore({
  id: "AO_JQTreeStore",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    getNewNode,
    getRawNode,
    getEditingNode,
    clearEditingNode,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function getNewNode(name) {
	const node = structuredClone(NODE_INFO);
	node.name = name;
	node.id = generateSimpleId("ao_cat");
	return node;
}

// ------------------------------------------------------------------------------------------------

function getRawNode() {
	return structuredClone(NODE_INFO);
}

// ------------------------------------------------------------------------------------------------

function getEditingNode() {
  return this.node
}

// ------------------------------------------------------------------------------------------------

function clearEditingNode() {
	this.node = assign({}, NODE_INFO);
}

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.node = assign({}, NODE_INFO);
  this.modal = {
    opened: false,
    title: "",
    message: "",
  };
  this.JQTreeProps = {
    // propriedades JQTree
    isExpanded: true,
  };
}

// ------------------------------------------------------------------------------------------------

export default JQTreeStore;

// ------------------------------------------------------------------------------------------------
