import { defineStore } from "pinia";
import { assign, base64To, JSONTo, normalize } from "misc-helpers";
import { getFetch, postFetch } from "../utils/rawFetch.js";
import { Notify, Loading, is } from "quasar";
import { handleError } from "@/utils/functions.js";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  user: {
    email: false,
    id: false,
    nickname: false,
    created: false,
    email_validated: false,
    info: false,
    started_at: false,
    valid_until: false,
    categories: {},
  },
};

// ------------------------------------------------------------------------------------------------

const userStore = defineStore({
  id: "AO_User",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    transformCategoriesInKeyValues,
    findCategoryByName,
    $reset,
    pingSession,
    info,
    logout,
    challengeWebAuthn,
    webAuthnCredentialRegistration,
    webAuthnCredentialRemoval,
    webauthnCredentialAuthentication,
    login,
    signup,
    registerEmail,
    changePassword,
    changePasswordLogged,
    forgetPassword,
    validateEmail,
    editCategory,
    upload,
    listUploads,
    revertUpload,
    deleteUser,
    exportData,
  },
});

// ------------------------------------------------------------------------------------------------

function transformCategoriesInKeyValues(categories, res = {}) {
  for (let category of categories) {
    res[category.id] = category;
    if (category.children && category.children.length) {
      this.transformCategoriesInKeyValues(category.children, res);
    }
  }

  return res;
}

// ------------------------------------------------------------------------------------------------

function findCategoryByName(name, categories = false) {
  if (!categories) {
    name = normalize(name).toLowerCase();
    categories = this.user.categories;
  }
  for (let key in categories) {
    const catName = normalize(categories[key].name).toLowerCase();
    if (catName == name) {
      return key;
    }
    if (categories[key].children && categories[key].children.length) {
      const res = findCategoryByName(name, categories[key].children);
      if (res) {
        return res;
      }
    }
  }
  return false;
}

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.user = assign({}, stateProps.user);
}

// ------------------------------------------------------------------------------------------------

async function pingSession() {
  Loading.show({
    message: i18nT(`messages:requests.user.session.fetchPingSession`),
  });

  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_PING_SESSION
    );
    if (!res || !res.ack || !res.valid_until) {
      return false;
    }

    if (this.user.id) {
      this.user.valid_until = res.valid_until;
    }

    return res.valid_until;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function info() {
  Loading.show({
    message: i18nT(`messages:requests.user.info.getUserInfo`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_GET_INFO
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    this.user = res.user;

    this.user.categories = this.transformCategoriesInKeyValues(
      res.user.info.categories,
      {}
    );

    return {
      user: res.user,
      accounts: res.accounts,
    };
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function logout() {
  Loading.show({
    message: i18nT(`messages:requests.user.logout.logout`),
  });
  try {
    const res = await getFetch(import.meta.env.VITE_APP_GATEWAY_V1_USER_LOGOUT);
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.user.logout.success`),
    });

    this.$reset();

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function challengeWebAuthn(credential_id) {
  Loading.show({
    message: i18nT(`messages:requests.user.webauthn.challenge.request`),
  });
  try {
    const URL =
      import.meta.env.VITE_APP_GATEWAY_V1_USER_CHALLENGE_WEBAUTHN +
      `${credential_id ? "?credential_id=" + credential_id : ""}`;
    const res = await getFetch(URL);
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.user.webauthn.challenge.success`),
    });

    return {
      challenge: res.challenge,
      cuid: res.cuid,
    };
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function webAuthnCredentialRegistration(challenge, webauthnRegistration) {
  Loading.show({
    message: i18nT(
      `messages:requests.user.webauthn.credentialRegistration.request`
    ),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_WEBAUTHN_CREDENTIAL_REGISTRATION,
      {
        body: {
          credentialInfo: webauthnRegistration,
          challenge,
        },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(
        `messages:requests.user.webauthn.credentialRegistration.savedSuccess`
      ),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function webAuthnCredentialRemoval(credentialId) {
  Loading.show({
    message: i18nT(`messages:requests.user.webauthn.credentialRemoval.request`),
  });

  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_WEBAUTHN_CREDENTIAL_REMOVAL,
      {
        body: { credentialId },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(
        `messages:requests.user.webauthn.credentialRemoval.savedSuccess`
      ),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function webauthnCredentialAuthentication(
  challenge,
  webauthnAssertion,
  counter
) {
  Loading.show({
    message: i18nT(
      `messages:requests.user.webauthn.credentialAuthentication.request`
    ),
  });
  try {
    const res = await postFetch(
      import.meta.env
        .VITE_APP_GATEWAY_V1_USER_WEBAUTHN_CREDENTIAL_AUTHENTICATION,
      {
        body: {
          assertionInfo: webauthnAssertion,
          challenge,
          counter,
        },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(
        `messages:requests.user.webauthn.credentialAuthentication.savedSuccess`
      ),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function login(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.login.fetchLogin`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_LOGIN,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.home.login.onSuccess`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function signup(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.signUp.fetchSignUp`),
  });

  try {
    data.html = {
      subject: i18nT(`messages:requests.home.registerEmail.email.subject`),
      welcomeTitle: i18nT(
        `messages:requests.home.registerEmail.email.welcomeTag`
      ),
      welcomeMessage: i18nT(
        `messages:requests.home.registerEmail.email.welcomeMessage`
      ),
      validateEmailButton: i18nT(
        `messages:requests.home.registerEmail.email.validateEmailButton`
      ),
    };

    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_SIGNUP,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.home.signUp.onSuccess`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function registerEmail(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.registerEmail.fetchRegisterEmail`),
  });
  try {
    data.html = {
      subject: i18nT(`messages:requests.home.registerEmail.email.subject`),
      welcomeTitle: i18nT(
        `messages:requests.home.registerEmail.email.welcomeTag`
      ),
      welcomeMessage: i18nT(
        `messages:requests.home.registerEmail.email.welcomeMessage`
      ),
      validateEmailButton: i18nT(
        `messages:requests.home.registerEmail.email.validateEmailButton`
      ),
    };
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_REGISTER_EMAIL,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.home.registerEmail.onSuccess"),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function changePassword(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.changePassword.fetchChangePassword`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_HOME_CHANGE_PASSWORD,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.home.changePassword.onSuccess"),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function changePasswordLogged(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.changePassword.fetchChangePassword`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_CHANGE_PASSWORD,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.home.changePassword.onSuccess"),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function forgetPassword(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.forgetPassword.fetchForgetPassword`),
  });
  try {
    data.html = {
      subject: i18nT(`messages:requests.home.forgetPassword.email.subject`),
      titleTag: i18nT(`messages:requests.home.forgetPassword.email.titleTag`),
      subTitleTag: i18nT(
        `messages:requests.home.forgetPassword.email.subTitleTag`
      ),
      message: i18nT(`messages:requests.home.forgetPassword.email.message`),
      changePasswordButton: i18nT(
        `messages:requests.home.forgetPassword.email.changePasswordButton`
      ),
    };

    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_FORGET_PASSWORD,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.home.forgetPassword.onSuccess"),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function validateEmail(data) {
  Loading.show({
    message: i18nT(`messages:requests.home.validateEmail.fetchValidateEmail`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_VALIDATE_EMAIL,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.home.validateEmail.onSuccess"),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editCategory(categories) {
  Loading.show({
    message: i18nT(`messages:requests.category.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_CATEGORY_EDIT,
      {
        body: { categories },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.category.edit.success`),
    });

    this.user.info.categories = res.editedCategory;

    this.user.categories = this.transformCategoriesInKeyValues(
      res.editedCategory,
      {}
    );

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function upload(transactions, transfers, categories) {
  Loading.show({
    message: i18nT(`messages:requests.upload.file.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_UPLOAD_FILE,
      {
        body: { categories, transfers, transactions },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    this.user.info.categories = res.categories;

    this.user.categories = this.transformCategoriesInKeyValues(
      res.categories,
      {}
    );

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.upload.file.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listUploads() {
  Loading.show({
    message: i18nT(`messages:requests.upload.list.request`),
  });
  try {
    const res = await getFetch(import.meta.env.VITE_APP_GATEWAY_V1_UPLOAD_LIST);
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.upload.list.success`),
    });

    return res.uploads;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function revertUpload(upload_id) {
  Loading.show({
    message: i18nT(`messages:requests.upload.file.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_UPLOAD_REVERT,
      {
        body: { upload_id },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.upload.revert.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function deleteUser(data) {
  Loading.show({
    message: i18nT(`messages:requests.user.info.delete.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_DELETE,
      {
        body: data,
      }
    );

    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.user.info.delete.onSuccess"),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function exportData(data) {
  Loading.show({
    message: i18nT(`messages:requests.user.export.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_USER_EXPORT,
      {
        body: data,
        isBlob: true,
      }
    );

    if (!res || res?.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT("messages:requests.user.export.success"),
    });

    // Step 2: Read the Blob as text
    const text = await new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsText(res);
    });

    const readText = text.split("\n");
    const resultText = [];
    for (let line of readText) {
      let splitLine = line.replaceAll(";", "").replaceAll('"', "").split(",");
      if (splitLine.length > 8) {
        splitLine = [
          ...splitLine.slice(0, 3),
          splitLine.slice(3, splitLine.length - 4).join(""),
          ...splitLine.slice(splitLine.length - 4, splitLine.length),
        ];
      }
      if (splitLine[0] == "TF") {
        resultText.push(splitLine.join(";"));
        continue;
      }
      splitLine[5] =
        this.user.categories[splitLine[5]]?.name || t("common:categories.27");
      resultText.push(splitLine.join(";"));
    }

    const url = window.URL.createObjectURL(
      new Blob([resultText.join("\n")], { type: res.type })
    );
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `export_accounting_overview_${this.user.nickname}.csv`
    );
    document.body.appendChild(link);
    link.click();

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

export default userStore;

// ------------------------------------------------------------------------------------------------
