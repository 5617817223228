import { wbStore, i18nT } from '../../stores';
import { WB_LOG_PREFIX } from '../consts.js';
import { handleError } from '../../utils/functions.js';
import { Notify } from 'quasar';

// ------------------------------------------------------------------------------------------------

let APPSW_BFInstallEvent;
let isReady = false;

// ------------------------------------------------------------------------------------------------

window.addEventListener('beforeinstallprompt', (event) => {
	console.info(`${WB_LOG_PREFIX}Before Install Prompt executed`);

	// Prevent the mini-infobar from appearing on mobile
	event.preventDefault();
	APPSW_BFInstallEvent = event;
	setBeforeInstallPromptChoiceMessage();
	isReady = true;
});

// ------------------------------------------------------------------------------------------------

function setBeforeInstallPromptChoiceMessage() {
	if (APPSW_BFInstallEvent.userChoice) {
		APPSW_BFInstallEvent.userChoice.then(async (choiceResult) => {
			try {
        if (choiceResult.outcome === 'accepted') {
          await wbStore.setWbBeforeInstallAnswer('accepted');
					Notify.create({
						message: i18nT('common:sw.installation.accepted'),
						color: 'positive',
						timeout: 10000
					});
					APPSW_BFInstallEvent = undefined;
					isReady = false;
				} else {
					await wbStore.setWbBeforeInstallAnswer('notAccepted');
					Notify.create({
						message: i18nT('common:sw.installation.canceled'),
						color: 'info',
						timeout: 10000
					})
				}
			} catch (error) {
				handleError(error);
			}
		});
	}
}

// ------------------------------------------------------------------------------------------------

export async function promptInstallSW() {
	try {
		await wbStore.setWbBeforeInstallAnswer('prompting');
		if (APPSW_BFInstallEvent) {
			APPSW_BFInstallEvent.prompt();
			return true;
		}
		return false;
	} catch (error) {
		handleError(error);
		return false;
	}
}

// ------------------------------------------------------------------------------------------------

export function isReadyToInstall() {
	return isReady;
}

// ------------------------------------------------------------------------------------------------
