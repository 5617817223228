import { defineStore } from "pinia";
import { getFetch, postFetch } from "../utils/rawFetch.js";
import { handleError } from "@/utils/functions.js";
import { Loading, Notify } from "quasar";
import { assign } from "misc-helpers";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  creditCards: [],
  creditCard: {},
};

// ------------------------------------------------------------------------------------------------

const creditCardStore = defineStore({
  id: "AO_CreditCard",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    createCreditCard,
    listCreditCards,
    editCreditCard,
    findCC,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.creditCards = [];
  this.creditCard = {};
}

// ------------------------------------------------------------------------------------------------

function findCC(ccId) {
  return this.creditCards.find((cc) => cc.id == ccId);
}

// ------------------------------------------------------------------------------------------------

async function createCreditCard(creditCard) {
  Loading.show({
    message: i18nT(`messages:requests.creditCard.create.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_CREDIT_CARD_CREATE,
      {
        body: creditCard,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.creditCard.create.success`),
    });

    this.creditCards.push(res.newCreditCard);

    return res.newCreditCard;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listCreditCards() {
  Loading.show({
    message: i18nT(`messages:requests.creditCard.list.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_CREDIT_CARD_LIST
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.creditCards = res.creditCards;
    return res.creditCards;
  } catch (error) {
    this.creditCards = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editCreditCard(editedCreditCard) {
  Loading.show({
    message: i18nT(`messages:requests.creditCard.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_CREDIT_CARD_EDIT,
      {
        body: editedCreditCard,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.creditCard.edit.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

export default creditCardStore;

// ------------------------------------------------------------------------------------------------
