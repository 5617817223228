import { defineStore } from "pinia";
import { Dark } from "quasar";
import { assign } from "misc-helpers";

// ------------------------------------------------------------------------------------------------

const stateProps = {
  darkTheme: false,
  language: false,
  menuOpened: false,
  credentials: [],
  webauthns: [],
  supportedLngs: ['en-us', 'pt-br', 'it-it', 'es-es', 'fr-fr', 'de-de', 'eo-eo', 'zh-cn', 'ja-jp', 'hi-in'],
  options: {
    sortTransactions: 1, // -1 = desc, 1 = asc
  },
  filters: {
    accounts: {},
  },
  startDate: null,
  endDate: null,
};

// ------------------------------------------------------------------------------------------------

const appStore = defineStore({
  id: "AO_App",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    toggleTheme,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function toggleTheme() {
  Dark.toggle();
  this.darkTheme = Dark.mode;
}

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.darkTheme = stateProps.darkTheme;
}

// ------------------------------------------------------------------------------------------------

export default appStore;

// ------------------------------------------------------------------------------------------------
