import { defineStore } from "pinia";
import { getFetch, postFetch } from "../utils/rawFetch.js";
import { handleError } from "@/utils/functions.js";
import { Loading, Notify } from "quasar";
import { assign, normalize } from "misc-helpers";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  accounts: [],
  account: {},
};

// ------------------------------------------------------------------------------------------------

const accountStore = defineStore({
  id: "AO_Account",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    createAccount,
    listAccounts,
    editAccount,
    findAccount,
    findAccountByName,
    orderAccounts,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.accounts = [];
  this.account = {};
}

// ------------------------------------------------------------------------------------------------

function findAccount(accId) {
  return this.accounts.find((acc) => acc.id == accId);
}

// ------------------------------------------------------------------------------------------------

function findAccountByName(accName) {
  return this.accounts.find(
    (acc) =>
      normalize(acc.account_name).toLowerCase() ==
      normalize(accName).toLowerCase()
  );
}

// ------------------------------------------------------------------------------------------------

async function createAccount(account) {
  Loading.show({
    message: i18nT(`messages:requests.account.create.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_ACCOUNT_CREATE,
      {
        body: account,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.account.create.success`),
    });

    this.accounts.push(res.newAccount);
    this.orderAccounts();
    return res.newAccount;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listAccounts() {
  Loading.show({
    message: i18nT(`messages:requests.account.list.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_ACCOUNT_LIST
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.accounts = res.accounts;
    this.orderAccounts();
    return this.accounts;
  } catch (error) {
    this.accounts = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editAccount(editedAccount) {
  Loading.show({
    message: i18nT(`messages:requests.account.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_ACCOUNT_EDIT,
      {
        body: editedAccount,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.account.edit.success`),
    });

    this.accounts = this.accounts.map((acc) => {
      if (acc.id == editedAccount.id) {
        return editedAccount;
      }
      return acc;
    });

    this.orderAccounts();
    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

function orderAccounts() {
  this.accounts.sort((a, b) => (a.account_name > b.account_name ? 1 : -1));
}

// ------------------------------------------------------------------------------------------------

export default accountStore;

// ------------------------------------------------------------------------------------------------
