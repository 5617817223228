import { t } from 'i18next';
import { messageEncryptToChunks, base64To } from 'misc-helpers';

// ------------------------------------------------------------------------------------------------

function mountOptions(options = {}) {
	options = {
		...options,
		// mode: options.mode || 'same-origin',
		// cache: options.cache || 'no-cache',
		// credentials: options.credentials || 'same-origin',
		headers: options.headers,
		redirect: options.redirect || 'follow',
		referrerPolicy: options.referrerPolicy || 'no-referrer'
	};
	return options;
}

// ------------------------------------------------------------------------------------------------

async function execute(path, options, statusExpected = 200) {
	try {
		let data;

		const isBlob = options.isBlob;
		if (isBlob) {
			delete options.isBlob;
		}

		options = mountOptions(options);
		const res = await fetch(`${location.origin}/${path}`, options);
		if (options.method == 'HEAD') {
			return res;
		}
		if (res && res.url && !isBlob) {
			data = await res.json();
		} else {
			data = await res.blob();
		}
		if (res.status != statusExpected) {
			throw {
				statusExpected,
				status: res.status,
				message: data.message || data.error,
				more_info: data.more_info
			};
		}
		return data;
	} catch (error) {
		if (!error.message) {
			error.message = error.error || error;
		}
		if (error.message.includes('messages:requests')) {
			if (error.message.includes(',')) {
				const splitted = error.message.split(',');
				const parsed = JSON.parse(splitted[1]);
				error.message = t(splitted[0], parsed);
			} else {
				error.message = t(error.message);
			}
		} else if (error.message == 'Failed to fetch') {
			error.message = t('messages:requests.rawFetch.fetchFail');
		}
		throw {
			message: error.message,
			more_info: error.more_info
		};
	}
}

// ------------------------------------------------------------------------------------------------

async function getFetch(path, options = {}, statusExpected) {
	try {
		options.method = 'GET';
		let queryString = '';
		if (options.query) {
			queryString = '?' + new URLSearchParams(options.query).toString();
		}
		return await execute(path + queryString, options, statusExpected);
	} catch (error) {
		throw error;
	}
}

// ------------------------------------------------------------------------------------------------

async function postFetch(path, options = {}, statusExpected) {
	try {
		if (!options.body) {
			throw {
				message: t('messages:requests.rawFetch.emptyFetch')
			};
		}

		options.method = 'POST';
		options.headers = {
			'Content-Type': 'application/json'
		};

		const data = await messageEncryptToChunks(
			import.meta.env.VITE_APP_PUBLIC_KEY,
			base64To(encodeURIComponent(JSON.stringify(options.body)))
		);
		options.body = JSON.stringify({ data });

		return await execute(path, options, statusExpected);
	} catch (error) {
		throw error;
	}
}

// ------------------------------------------------------------------------------------------------

async function headFetch(path, throwsException = false) {
	try {
		return await execute(path, { method: 'HEAD' });
	} catch (error) {
		throw error;
	}
}

// ------------------------------------------------------------------------------------------------

async function deleteFetch(path, options = {}, statusExpected) {
	try {
		options.method = 'DELETE';
		let queryString = '';
		if (options.query) {
			queryString = '?' + new URLSearchParams(options.query).toString();
		}
		return await execute(path + queryString, options, statusExpected);
	} catch (error) {
		throw error;
	}
}

// ------------------------------------------------------------------------------------------------

export { getFetch, postFetch, headFetch, deleteFetch };

// ------------------------------------------------------------------------------------------------
