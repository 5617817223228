<script setup>
import { ref, onMounted, watch } from "vue";
import { RouterView } from "vue-router";
import { handleError } from "./utils/functions";
import { useQuasar } from 'quasar'
import i18next from 'i18next';

// ------------------------------------------------------------------------------------------------

const appStore = ref({});
const isMounted = ref(false);
const isLanguageReady = ref(false);
const isDarkTheme = ref(false);
const currentLng = ref('en-us');
const languages = ref({});
const supportedLngs = ['en-us', 'pt-br', 'it-it', 'es-es', 'fr-fr', 'de-de', 'eo-eo', 'zh-cn', 'ja-jp', 'hi-in'];
const quasarLangName = {
  'en-us': 'en-US',
  'pt-br': 'pt-BR',
  'it-it': 'it',
  'es-es': 'es',
  'fr-fr': 'fr',
  'de-de': 'de',
  'eo-eo': 'eo',
  'zh-cn': 'zh-CN',
  'ja-jp': 'ja',
  'hi-in': 'hi'
}
const $q = useQuasar();
const modules = import.meta.glob('../node_modules/quasar/lang/(en-US|pt-BR|it|es|fr|de|eo|zh-CN|ja|hi).js')

// ------------------------------------------------------------------------------------------------

async function loadLanguage(langParam = 'en-us') {
  try {
    if (languages.value[langParam]) {
      return;
    }

    const language = {
      ...(await import(`../i18n/${langParam}/index.js`))
    }.default;

    for (let namespace in language) {
      i18next.addResourceBundle(langParam, namespace, language[namespace]);
    }

    languages.value[langParam] = language;
  } catch (error) {
    handleError(error);
  }
}

// ------------------------------------------------------------------------------------------------

async function init() {
  try {
    isLanguageReady.value = false;

    // if didn't load any language yet
    if (!isMounted.value) {
      await i18next.init({
        debug: false,
        lowerCaseLng: true,
        partialBundledLanguages: true,
        supportedLngs,
        fallbackLng: 'en-us'
      });
    }

    const stores = {
      ...(await import(`./stores/index.js`))
    };
    await stores.loadStores();
    appStore.value = stores.appStore;

    // if no language is set already
    // checks for navigator language
    // if not found, set default
    if (!appStore.value.language) {
      if (
        !supportedLngs.includes(navigator.language.toString().toLowerCase())
      ) {
        appStore.value.language = 'en-us';
      } else {
        appStore.value.language = navigator.language.toString().toLowerCase();
      }
    }

    await loadLanguage(appStore.value.language);

    // changing the language 
    await i18next.changeLanguage(appStore.value.language);

    modules[`../node_modules/quasar/lang/${quasarLangName[appStore.value.language]}.js`]().then(lang => {
      $q.lang.set(lang.default)
    })

    // updating the layout reactivity - mostly for header and footer
    isLanguageReady.value = true;
    currentLng.value = appStore.value.language;
    isDarkTheme.value = appStore.value.darkTheme;
  } catch (error) {
    handleError(error);
  }
}

// // ------------------------------------------------------------------------------------------------

onMounted(async () => {
  try {
    await init();

    isDarkTheme.value = appStore.value.darkTheme;
  } catch (error) {
    handleError(error);
  } finally {
    isMounted.value = true;
  }
});

// // ------------------------------------------------------------------------------------------------

watch(appStore, async () => {
  try {
    if (!isMounted.value) return;
    if (appStore.value.language == currentLng.value && appStore.value.darkTheme == isDarkTheme.value) return;
    await init();
  } catch (error) {
    handleError(error);
  }
}, { deep: true });

// ------------------------------------------------------------------------------------------------
</script>

<template>
  <RouterView v-if="isMounted && isLanguageReady" />
</template>
