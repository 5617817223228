import { BENEFICIARY_ROUTES, BENEFICIARY_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: BENEFICIARY_SECTION,
    name: "beneficiaryRoot",
    redirect: () => {
      return { name: BENEFICIARY_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: BENEFICIARY_ROUTES.CREATE.path,
        name: BENEFICIARY_ROUTES.CREATE.name,
        component: () => import("@/views/beneficiary/AO_BeneficiaryCreateView.vue"),
      },
      {
        path: BENEFICIARY_ROUTES.EDIT.path,
        name: BENEFICIARY_ROUTES.EDIT.name,
        component: () => import("@/views/beneficiary/AO_BeneficiaryEditView.vue"),
      },
      {
        path: BENEFICIARY_ROUTES.LIST.path,
        name: BENEFICIARY_ROUTES.LIST.name,
        component: () => import("@/views/beneficiary/AO_BeneficiaryListView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
