import { TRANSFER_ROUTES, TRANSFER_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: TRANSFER_SECTION,
    name: "transferRoot",
    redirect: () => {
      return { name: TRANSFER_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: TRANSFER_ROUTES.CREATE.path,
        name: TRANSFER_ROUTES.CREATE.name,
        component: () => import("@/views/transfer/AO_TransferCreateView.vue"),
      },
      {
        path: TRANSFER_ROUTES.EDIT.path,
        name: TRANSFER_ROUTES.EDIT.name,
        component: () => import("@/views/transfer/AO_TransferEditView.vue"),
      },
      {
        path: TRANSFER_ROUTES.LIST.path,
        name: TRANSFER_ROUTES.LIST.name,
        component: () => import("@/views/transfer/AO_TransferListView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
