import { defineStore } from "pinia";
import { assign } from "misc-helpers";
import { spaStorage as SS } from "./storage.js";
import { handleError } from "../utils/functions.js";

// ------------------------------------------------------------------------------------------------

const stateProps = {
  isAppMode: false,
  wbBeforeInstallAnswer: "notAnswered",
};

const BEFORE_INSTALL_ANSWER_KEY = "AOWB_BIAK";

// ------------------------------------------------------------------------------------------------

const workboxStore = defineStore({
  id: "AO_Workbox",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    setWbBeforeInstallAnswer,
    getWbBeforeInstallAnswer,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

async function setWbBeforeInstallAnswer(wbBeforeInstallAnswer = "notAnswered") {
  try {
    const BIAK = await SS.getItem(BEFORE_INSTALL_ANSWER_KEY);

    if (!BIAK && wbBeforeInstallAnswer != "skip") {
      this.wbBeforeInstallAnswer = wbBeforeInstallAnswer;
    } else if (!BIAK && wbBeforeInstallAnswer == "skip") {
      this.wbBeforeInstallAnswer = wbBeforeInstallAnswer;
      await SS.setItem(
        BEFORE_INSTALL_ANSWER_KEY,
        wbBeforeInstallAnswer,
        604800000
      ); // 7 days
    } else if (["skip", "prompting"].includes(wbBeforeInstallAnswer)) {
      this.wbBeforeInstallAnswer = wbBeforeInstallAnswer;
    }
  } catch (error) {
    handleError(error);
  }
}

// ------------------------------------------------------------------------------------------------

async function getWbBeforeInstallAnswer() {
  try {
    const storeWbBeforeInstallAnswer = await SS.getItem(
      BEFORE_INSTALL_ANSWER_KEY
    );
    return storeWbBeforeInstallAnswer || this.wbBeforeInstallAnswer;
  } catch (error) {
    handleError(error);
  }
}

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.isAppMode = false;
  this.wbBeforeInstallAnswer = "notAnswered";
}

// ------------------------------------------------------------------------------------------------

export default workboxStore;

// ------------------------------------------------------------------------------------------------
