import { INSTALLMENT_ROUTES, INSTALLMENT_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: INSTALLMENT_SECTION,
    name: "installmentRoot",
    redirect: () => {
      return { name: INSTALLMENT_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: INSTALLMENT_ROUTES.CREATE.path,
        name: INSTALLMENT_ROUTES.CREATE.name,
        component: () => import("@/views/installment/AO_InstallmentCreateView.vue"),
      },
      {
        path: INSTALLMENT_ROUTES.EDIT.path,
        name: INSTALLMENT_ROUTES.EDIT.name,
        component: () => import("@/views/installment/AO_InstallmentEditView.vue"),
      },
      {
        path: INSTALLMENT_ROUTES.LIST.path,
        name: INSTALLMENT_ROUTES.LIST.name,
        component: () => import("@/views/installment/AO_InstallmentListView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
