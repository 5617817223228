import { createApp } from "vue";
import { Quasar, Loading, Notify, Dialog } from "quasar";
import App from "./App.vue";
import router from "./router";

// ------------------------------------------------------------------------------------------------

// Import quasar icon libraries
import "@quasar/extras/material-icons/material-icons.css";
import "@quasar/extras/material-icons-outlined/material-icons-outlined.css";
// import "@quasar/extras/material-icons-round/material-icons-round.css";
// import "@quasar/extras/material-icons-sharp/material-icons-sharp.css";

// Import quasar animations
import "@quasar/extras/animate/fadeIn.css";
import "@quasar/extras/animate/fadeOut.css";
import "@quasar/extras/animate/slideInDown.css";
import "@quasar/extras/animate/slideOutUp.css";

// Import Quasar css
import "quasar/src/css/index.sass";

// Import Quasar language pack
import langEnUS from "quasar/lang/en-US";

// ------------------------------------------------------------------------------------------------

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

// -----------------------------  app css  --------------------------------------------------------

import "./assets/css/main.scss";

// ------------------------------------------------------------------------------------------------

const app = createApp(App);

// ------------------------------------------------------------------------------------------------

app.use(router);
app.use(Quasar, {
  plugins: { Loading, Notify, Dialog }, // import Quasar plugins and add here
  config: {
    loading: { delay: 400 },
    notify: { position: "top", progress: true }
  },
  lang: langEnUS
});

// ------------------------------------------------------------------------------------------------

app.component('font-awesome-icon', FontAwesomeIcon);

// ------------------------------------------------------------------------------------------------

app.mount("#app");

// ------------------------------------------------------------------------------------------------

// Define <stripe-buy-button> as a custom element
app.config.compilerOptions.isCustomElement = tag => tag === 'stripe-buy-button';


import * as Workbox from "./serviceWorker/index.js";