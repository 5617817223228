import { wbStore } from "../stores";
import { WB_LOG_PREFIX } from "./consts.js";
import { handleError } from "../utils/functions.js";
import * as BF_INSTALL_PROMPT_EVENT from "./events/beforeInstallPromptEvent.js";
import updateWorkbox from "./events/updateWorkbox.js";

// ------------------------------------------------------------------------------------------------

let SW_REG;

// ------------------------------------------------------------------------------------------------

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js", { scope: "/" })
    .then((reg) => {
      SW_REG = reg;

      console.info(`${WB_LOG_PREFIX}registration successful`);

      addUpdateEventListener(reg);
    })
    .catch((err) => {
      handleError(`${WB_LOG_PREFIX}WBError: ${err}`);
    });
		
  navigator.serviceWorker.addEventListener("controllerchange", () => {
    if (isRefreshing) return;
    window.location.reload();
    isRefreshing = true;
  });
}
// ------------------------------------------------------------------------------------------------

// It works together with addUpdateEventListener
let isRefreshing = false;

// ------------------------------------------------------------------------------------------------

function addUpdateEventListener(reg) {
  reg.addEventListener("updatefound", () => {
    console.info(`${WB_LOG_PREFIX}service work update found!`);

    const newWorker = reg.installing;
    newWorker.addEventListener("statechange", async () => {
      switch (newWorker.state) {
        case "installed":
          try {
            if (navigator.serviceWorker.controller) {
              await updateWorkbox(newWorker);
            }
          } catch (error) {
            handleError(error);
          }
          break;
      }
    });
  });
}

// ------------------------------------------------------------------------------------------------

export const promptInstallSW = BF_INSTALL_PROMPT_EVENT.promptInstallSW;
export const isReadyToInstall = BF_INSTALL_PROMPT_EVENT.isReadyToInstall;
export const getSWReg = () => SW_REG;

// ------------------------------------------------------------------------------------------------

export function isAppInstalled() {
  try {
    if (window.matchMedia("(display-mode: standalone)").matches) {
      wbStore.isAppMode = true;
      return true;
    }
    wbStore.isAppMode = false;
  } catch (error) {
    handleError(`${WB_LOG_PREFIX}${error.message}`);
  }
  return false;
}

// ------------------------------------------------------------------------------------------------
