import { CATEGORY_SECTION, CATEGORY_ROUTES } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: CATEGORY_SECTION,
    name: "categoryRoot",
    redirect: () => {
      return { name: CATEGORY_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: CATEGORY_ROUTES.LIST.path,
        name: CATEGORY_ROUTES.LIST.name,
        component: () => import("@/views/category/AO_CategoriesView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
