import { UPLOAD_ROUTES, UPLOAD_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: UPLOAD_SECTION,
    name: "uploadRoot",
    redirect: () => {
      return { name: UPLOAD_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: UPLOAD_ROUTES.FILE.path,
        name: UPLOAD_ROUTES.FILE.name,
        component: () => import("@/views/upload/file/AO_UploadFile.vue"),
      },
      {
        path: UPLOAD_ROUTES.LIST.path,
        name: UPLOAD_ROUTES.LIST.name,
        component: () => import("@/views/upload/AO_UploadList.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
