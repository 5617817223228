import { ACCOUNT_ROUTES, ACCOUNT_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: ACCOUNT_SECTION,
    name: "accountRoot",
    redirect: () => {
      return { name: ACCOUNT_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: ACCOUNT_ROUTES.CREATE.path,
        name: ACCOUNT_ROUTES.CREATE.name,
        component: () => import("@/views/account/AO_AccountCreateView.vue"),
      },
      {
        path: ACCOUNT_ROUTES.EDIT.path,
        name: ACCOUNT_ROUTES.EDIT.name,
        component: () => import("@/views/account/AO_AccountEditView.vue"),
      },
      {
        path: ACCOUNT_ROUTES.LIST.path,
        name: ACCOUNT_ROUTES.LIST.name,
        component: () => import("@/views/account/AO_AccountListView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
