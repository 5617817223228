import { defineStore } from "pinia";
import { getFetch, postFetch, deleteFetch } from "../utils/rawFetch.js";
import { handleError } from "@/utils/functions.js";
import { Loading, Notify } from "quasar";
import { assign } from "misc-helpers";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  transfers: [],
  transfer: {},
  favoriteTransfers: [],
};

// ------------------------------------------------------------------------------------------------

const transferStore = defineStore({
  id: "AO_Transfer",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    createTransfer,
    listTransfers,
    editTransfer,
    deleteTransfer,
    favoriteTransfers,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.transfers = [];
  this.transfer = {};
}

// ------------------------------------------------------------------------------------------------

async function createTransfer(transfer) {
  Loading.show({
    message: i18nT(`messages:requests.transfer.create.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSFER_CREATE,
      {
        body: transfer,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.transfer.create.success`),
    });

    this.transfers.push(res.newTransfer);

    return res.newTransfer;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listTransfers(filters = {}) {
  Loading.show({
    message: i18nT(`messages:requests.transfer.list.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSFER_LIST,
      {
        query: filters,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.transfers = res.transfers;
    return res.transfers;
  } catch (error) {
    this.transfers = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editTransfer(editedTransfer) {
  Loading.show({
    message: i18nT(`messages:requests.transfer.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSFER_EDIT,
      {
        body: editedTransfer,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.transfer.edit.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function deleteTransfer(id) {
  Loading.show({
    message: i18nT(`messages:requests.transfer.delete.request`),
  });
  try {
    const res = await deleteFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSFER_DELETE,
      {
        query: { id },
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.transfer.delete.success`),
    });

    this.transfers = this.transfers.filter((transfer) => transfer.id != id);

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function favoriteTransfers() {
  Loading.show({
    message: i18nT(`messages:requests.transfer.favorite.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_TRANSFER_FAVORITE
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.favoriteTransfers = res.transfers;
    return this.favoriteTransfers;
  } catch (error) {
    this.favoriteTransfers = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

export default transferStore;

// ------------------------------------------------------------------------------------------------
