import { defineStore } from "pinia";
import { getFetch, postFetch } from "../utils/rawFetch.js";
import { handleError } from "@/utils/functions.js";
import { Loading, Notify } from "quasar";
import { assign, normalize } from "misc-helpers";
import { t } from "i18next";
const i18nT = t;

// ------------------------------------------------------------------------------------------------

const stateProps = {
  beneficiaries: [],
  beneficiary: {},
};

// ------------------------------------------------------------------------------------------------

const beneficiaryStore = defineStore({
  id: "AO_Beneficiary",
  state: () => assign({}, stateProps),
  getters: {},
  actions: {
    createBeneficiary,
    listBeneficiaries,
    editBeneficiary,
    findBeneficiaryByName,
    $reset,
  },
});

// ------------------------------------------------------------------------------------------------

function $reset() {
  this.beneficiaries = [];
  this.beneficiary = {};
}

// ------------------------------------------------------------------------------------------------

function findBeneficiaryByName(name) {
  name = normalize(name).toLowerCase();
  return this.beneficiaries.find(
    (beneficiary) => normalize(beneficiary.beneficiary_name).toLowerCase() == name
  );
}

// ------------------------------------------------------------------------------------------------

async function createBeneficiary(beneficiary) {
  Loading.show({
    message: i18nT(`messages:requests.beneficiary.create.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_BENEFICIARY_CREATE,
      {
        body: beneficiary,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.beneficiary.create.success`),
    });

    this.beneficiaries.push(res.newBeneficiary);

    return res.newBeneficiary;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function listBeneficiaries() {
  Loading.show({
    message: i18nT(`messages:requests.beneficiary.list.request`),
  });
  try {
    const res = await getFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_BENEFICIARY_LIST
    );
    if (!res || res.message) {
      throw res?.message || res;
    }
    this.beneficiaries = res.beneficiaries;
    return res.beneficiaries;
  } catch (error) {
    this.beneficiaries = [];
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

async function editBeneficiary(editedBeneficiary) {
  Loading.show({
    message: i18nT(`messages:requests.beneficiary.edit.request`),
  });
  try {
    const res = await postFetch(
      import.meta.env.VITE_APP_GATEWAY_V1_BENEFICIARY_EDIT,
      {
        body: editedBeneficiary,
      }
    );
    if (!res || res.message) {
      throw res?.message || res;
    }

    Notify.create({
      type: "positive",
      message: i18nT(`messages:requests.beneficiary.edit.success`),
    });

    return true;
  } catch (error) {
    handleError(error);
  } finally {
    Loading.hide();
  }
}

// ------------------------------------------------------------------------------------------------

export default beneficiaryStore;

// ------------------------------------------------------------------------------------------------
