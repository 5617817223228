import { TRANSACTION_ROUTES, TRANSACTION_SECTION } from "./routeIndex";

// ------------------------------------------------------------------------------------------------

const routes = [
  {
    path: TRANSACTION_SECTION,
    name: "transactionRoot",
    redirect: () => {
      return { name: TRANSACTION_ROUTES.LIST.name };
    },
    component: () => import("@/layouts/AO_Layout.vue"),
    children: [
      {
        path: TRANSACTION_ROUTES.CREATE.path,
        name: TRANSACTION_ROUTES.CREATE.name,
        component: () => import("@/views/transaction/AO_TransactionCreateView.vue"),
      },
      {
        path: TRANSACTION_ROUTES.EDIT.path,
        name: TRANSACTION_ROUTES.EDIT.name,
        component: () => import("@/views/transaction/AO_TransactionEditView.vue"),
      },
      {
        path: TRANSACTION_ROUTES.LIST.path,
        name: TRANSACTION_ROUTES.LIST.name,
        component: () => import("@/views/transaction/AO_TransactionListView.vue"),
      },
      // ------------------------------------------------------------------------------------------
      // deve ser o último sempre - 404
      {
        path: "/:pathMatch(.*)*",
        name: "notFound",
        component: () => import("@/views/home/AO_NotFoundView.vue"),
      },
    ],
  },
];

// ------------------------------------------------------------------------------------------------

export default routes;

// ------------------------------------------------------------------------------------------------
