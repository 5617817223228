import { Notify } from "quasar";
import VMasker from "vanilla-masker";
import router from "@/router";
import * as CONSTS from "@/stores/consts";

// ------------------------------------------------------------------------------------------------

export function handleError(
  error = { message: "Erro Interno - Não Identificado" },
  appendMessage = "",
  color = "negative",
  icon = "warning"
) {
  try {
    const message = `${appendMessage ? appendMessage + "<br />" : ""}${
      error.message || error
    }`;
    if (error && error.message && error.message.includes("\n")) {
      error.message = error.message.replace("\n", "<br />");
    }
    console.error(error);
    Notify.create({
      html: true,
      multiLine: true,
      message,
      color,
      icon,
    });
  } catch (fatalError) {
    throw { error, fatalError };
  }
}

// ------------------------------------------------------------------------------------------------

export function isPasswordValid(v = "") {
  const symbolRegex = /[\\\!\@\#\$\%\&\*\(\)\_\+\=\{\}\[\]\/\|\?\;\.\,\<\>\-]/;
  if (!v.match(/[A-zÀ-ú]/)) {
    return false;
  }
  if (!v.match(symbolRegex)) {
    return false;
  }
  if (!v.match(/[0-9]/)) {
    return false;
  }
  if (v.length < 10) {
    return false;
  }
  return true;
}

// ------------------------------------------------------------------------------------------------

export function pickColorBasedOnBackground(
  bgColor = "#FFFFFF",
  lightColor = "#FFFFFF",
  darkColor = "#000000"
) {
  let color = bgColor.charAt(0) === "#" ? bgColor.substring(1, 7) : bgColor;
  let r = parseInt(color.substring(0, 2), 16); // hexToR
  let g = parseInt(color.substring(2, 4), 16); // hexToG
  let b = parseInt(color.substring(4, 6), 16); // hexToB
  let uicolors = [r / 255, g / 255, b / 255];
  let c = uicolors.map((col) => {
    if (col <= 0.03928) {
      return col / 12.92;
    }
    return Math.pow((col + 0.055) / 1.055, 2.4);
  });
  let L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
  return L > 0.179 ? darkColor : lightColor;
}

// ------------------------------------------------------------------------------------------------

export const generateRandomColor = () => {
  return `#${Math.floor(Math.random() * 16777215)
    .toString(16)
    .padEnd(6, "a")}`;
};

// ------------------------------------------------------------------------------------------------

export function getColorByAmount(amount) {
  const isNegative = amount.toString().includes("-");
  if (!isNegative) {
    return `color: var(--ao-color-green);`;
  }
  return `color: var(--ao-color-red);`;
}

// ------------------------------------------------------------------------------------------------

export function getCurrency(value = 0, language) {
  return new Intl.NumberFormat(language, {
    style: "currency",
    currency: CONSTS.CURRENCY_BY_LOCALE[language],
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  }).format(value);
}

// ------------------------------------------------------------------------------------------------

export const formatText = (
  formatField = "99.999.999/9999-99",
  options = { isToPad: true }
) => {
  const length = (formatField.match(/9/g) || []).length;

  return (
    value = "",
    padLength = length,
    isToPad = options.isToPad,
    padString = options.padString || "0",
    padPos = options.padPos || "start"
  ) => {
    if (value === null) return value;
    if (!isToPad) {
      return VMasker.toPattern(value.toString(), formatField);
    }

    const text =
      padPos == "start"
        ? value.toString().padStart(padLength, padString)
        : value.toString().padEnd(padLength, padString);
    return VMasker.toPattern(text, formatField);
  };
};

// ------------------------------------------------------------------------------------------------

export async function loadStoreAsync() {
  try {
    const stores = await import("@/stores");
    await stores.loadStores();
    return stores;
  } catch (error) {
    throw error;
  }
}

// ------------------------------------------------------------------------------------------------

export function goTo(name) {
  if (name == "back") {
    router.go(-1)
    return;
  }
  router.push({ name });
}

// ------------------------------------------------------------------------------------------------

export function scrambleValue(targetValue, callback) {
  const characters = '0123456789';
  const currencySymbol = targetValue[0]; // Extrai o símbolo da moeda
  const duration = 1500;
  const iterations = Math.ceil(duration / 50);
  let currentIteration = 0;

  const scrambleLoop = () => {
    if (currentIteration < iterations) {
      let scrambledValue = '';
      for (let i = 1; i < targetValue.length; i++) {
        if (targetValue[i].match(/[0-9]/)) {
          scrambledValue += characters.charAt(Math.floor(Math.random() * characters.length));
        } else {
          scrambledValue += targetValue[i]; // Mantém símbolos de pontuação como vírgulas e pontos
        }
      }
      callback(currencySymbol + scrambledValue);
      currentIteration++;
      requestAnimationFrame(scrambleLoop);
    } else {
      callback(targetValue); // Define o valor final
    }
  };

  scrambleLoop();
}

// ------------------------------------------------------------------------------------------------

export function getLightAmountColor(amount) {
  const isNegative = amount.toString().includes("-");
  if (!isNegative) {
    return `var(--ao-color-green-light)`;
  }
  return `var(--ao-color-red-light)`;
}

// ------------------------------------------------------------------------------------------------